import React from 'react';
import './counter.css';

const Counter = () => {
  const counterData = [
    { number: 12, value: '4148 +', label: 'Families', iconClass: 'fa fa-user' },
    { number: 55, value: '14253 +', label: 'Family Members', iconClass: 'fa fa-users' },
    { number: 359, value: '50 +', label: 'Zones', iconClass: 'fa fa-map-marker' },
    { number: 246, value: '108 +', label: 'Anbiyam', iconClass: 'fa fa-university' },
  ];

  return (
    <>
      <div className="section-title text-center">
        <p className="subtitle">Statistics</p>
        <h4 className="title">Parish Statistics</h4>
      </div>
      <div id="projectFacts" className="sectionClass container-fluid">
        <div className="fullWidth eight columns">
          <div className="projectFactsWrap">
            {counterData.map((item, index) => (
              <div
                key={index}
                className="item wow fadeInUpBig animated animated"
                data-number={item.number}
                style={{ visibility: 'visible' }}
              >
                <i className={item.iconClass} />
                <p id={`number${index + 1}`} className="number">
                  {item.value}
                </p>
                <span />
                <p>{item.label}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Counter;
