import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const News = ({ allnewsdata }) => {
    const [loading, setLoading] = useState(true);

    const [data, setdata] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const noimage = "assets/images/img/noimage.webp";

    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split('-');
        const formattedDate = new Date(`${year}-${month}-${day}`);
        const dayOfMonth = formattedDate.getDate().toString().padStart(2, '0');
        const monthName = formattedDate.toLocaleDateString('en-US', { month: 'long' });

        return { day: dayOfMonth, month: monthName };
    };
    useEffect(() => {
        const newsevents = localStorage.getItem("HomeData");
        if (newsevents) {
          setdata(JSON.parse(newsevents));
          setLoading(false);
        }else{
          setdata(allnewsdata);
        }
    }, [allnewsdata]); 

    const latestEvents = data?.newsdata
        ? data?.newsdata
            .filter((news) => news.category_id === 13)
            .sort((a, b) => new Date(b.eventdate) - new Date(a.eventdate))
            .slice(0, 3)
        : [];

    return (
        <>
            <div className="events-area pt-70 pb-40">
                <div className="container">
                    <div className="section-title text-center">
                        <p className="subtitle">Read News</p>
                        <h4 className="title">Latest News</h4>
                    </div>
                    {latestEvents.length > 0 ? (
                        <div className="row justify-content-center">
                            {loading ? (
                                <div className='col-lg-12 text-center text-black'><b><center>Loading Data...</center></b></div>
                            ) : latestEvents.map((news, index) => (
                                <div className="col-lg-4 col-md-6" key={index}>
                                    <div className="single-events-card style-4">
                                        <div className="events-image">
                                            <Link to={`ournews?newsid=${news?.id}`}>
                                                <img src={news?.media_url || noimage} alt="No News" />
                                            </Link>
                                            <div className="date">
                                                <span>{formatDate(news?.eventdate).day}</span>
                                                <p>{formatDate(news?.eventdate).month}</p>
                                            </div>
                                        </div>
                                        <div className="events-content">
                                            <div className="admin">
                                                <p>
                                                    <i className="fa fa-users" />&nbsp;
                                                    By Admin
                                                </p>
                                            </div>
                                            <Link to={`ournews?newsid=${news?.id}`} className='latestnews'>
                                                <h3>{news?.title}</h3>
                                            </Link>
                                            <p>{news?.eventdate}</p>
                                            <Link to={`ournews?newsid=${news?.id}`} className="read-more-btn"> Read More <i className="flaticon-next" /></Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className='col-lg-12 text-center text-black mt-5'><b><center>No News Available</center></b></div>
                    )}
                </div>
            </div>
        </>
    );
};

export default News;
