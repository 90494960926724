import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../Components/LightboxGallery.css";
import { ApiUrl } from "../../API/ApiUrl";

const LightboxGallery = () => {
  const [priest, setPriest] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lightboxImage, setLightboxImage] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/get/teammembers`);
        const updatedPriestData = response.data.teams;
        setPriest(updatedPriestData);
        localStorage.setItem("Parishpriest", JSON.stringify(updatedPriestData));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching images:", error);
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  const latestimage = priest.slice(0, 9);

  console.log(priest);

  const openLightbox = (priest) => {
    setLightboxImage(priest);
    document.body.style.overflow = "hidden";
  };

  const closeLightbox = () => {
    setLightboxImage(null);
    document.body.style.overflow = "";
  };

  if (loading) return <div className="col text-center text-black"><b><center>Loading...</center></b></div>;

  return (
    <div className="row">
      {latestimage.length === 0 ? (
        <div className="col text-center text-black"><b><center>No images found</center></b></div>
      ) : (
        latestimage.map((image, index) => (
          <div className="col-lg-3 col-md-6 mb-4" key={index}>
            <img
              src={image.media_url}
              alt='nodata'
              className="img-fluid rounded lightbox-thumbnail"
              style={{ cursor: "pointer",boxShadow:"2px 2px 5px rgba(0, 0, 0, 0.2)" }}
              onClick={() => openLightbox(image)}
            />
            <h5 className="text-center mt-2">{image.title}</h5>
            <h6 className="text-center">{image.role}</h6>
          </div>
        ))
      )}
      {lightboxImage && (
        <div className="lightbox-overlay" onClick={closeLightbox}>
          <button className="close-button" onClick={closeLightbox}>
            &times;
          </button>
          <div className="lightbox-container">
            <img src={lightboxImage.media_url} alt='nodata' className="img-fluid rounded lightbox-image" />
          </div>
        </div>
      )}
    </div>
  );
};

const MeetOurParish = () => {
  return (
    <>
      <div className="container">
        <div className="section-title text-center">
          <h4 className="title">Meet Our Priests</h4>
        </div>
        <LightboxGallery />
      </div>
    </>
  )
}

export default MeetOurParish;
