import React, { useEffect, useState } from "react";
import axios from "axios";
import { ApiUrl } from "../API/ApiUrl";
import "./LightboxGallery.css";

const LightboxGallery = () => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [lightboxImage, setLightboxImage] = useState(null);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const cachedData = localStorage.getItem("galleryImages");
                if (cachedData) {
                    setImages(JSON.parse(cachedData));
                } else {
                    const response = await axios.get(`${ApiUrl}/get/gallery_images`);
                    setImages(response.data.data);
                    localStorage.setItem("galleryImages", JSON.stringify(response.data.data));
                }
            } catch (error) {
                console.error("Error fetching images:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchImages();
    }, []);

    const latestimage = images?.sort((a, b) => b.id - a.id).slice(0, 6);

    const openLightbox = (image) => {
        setLightboxImage(image);
        document.body.style.overflow = "hidden";
    };

    const closeLightbox = () => {
        setLightboxImage(null);
        document.body.style.overflow = "";
    };

    if (loading) return <div className="col text-center text-black"><b><center>Loading...</center></b></div>;

    return (
        <div className="row">
            {latestimage && latestimage.length === 0 ? (
                <div className="col text-center text-black"><b><center>No images found</center></b></div>
            ) : (
                latestimage.map((image, index) => (
                    <div className="col-lg-4 col-md-6 mb-4" key={index}>
                        <img
                            src={image.image}
                            alt='nodata'
                            className="img-fluid rounded lightbox-thumbnail"
                            style={{ cursor: "pointer", boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)" }}
                            onClick={() => openLightbox(image)}
                        />
                    </div>
                ))
            )}
            {lightboxImage && (
                <div className="lightbox-overlay" onClick={closeLightbox}>
                    <button className="close-button" onClick={closeLightbox}>
                        &times;
                    </button>
                    <div className="lightbox-container">
                        <img src={lightboxImage.image} alt='nodata' className="img-fluid rounded lightbox-image" />
                    </div>
                </div>
            )}
        </div>
    );
};
const HomeBox = () => {
    return (
        <div className="container">
            <div className="section-title text-center">
                <p className="subtitle">View Gallery</p>
                <h4 className="title">Our Gallery</h4>
            </div>
            <LightboxGallery />
        </div>
    );
};

export default HomeBox;