import { useQuery } from 'react-query';
import axios from 'axios';
import { ApiUrl } from '../API/ApiUrl';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const AboutUs = () => {
    const [cachedData, setCachedData] = useState(null);

    const { data: messagesData, isLoading, isError } = useQuery('messages', () =>
        axios.get(`${ApiUrl}/get/messages/1`).then((res) => res.data.data)
    );

    const latestMessage = cachedData ? cachedData[cachedData.length - 1] : null;

    useEffect(() => {
        if (!isLoading && !isError && messagesData) {
            setCachedData(messagesData);
            localStorage.setItem('priestmessagesData', JSON.stringify(messagesData));
        }
    }, [messagesData, isLoading, isError]);

    useEffect(() => {
        const cachedDataStr = localStorage.getItem('priestmessagesData');
        if (cachedDataStr) {
            setCachedData(JSON.parse(cachedDataStr));
        }
    }, []);

    return (
        <>
            <div className="campus-information-area pb-50">
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-9'>
                            <h2>Parish Priest Message</h2>
                            <div className="event">
                                <div className=" event-icon-weekdays">
                                    {isLoading ? (
                                        <SkeletonTheme baseColor="#202020" highlightColor="#444">
                                            <Skeleton height={300} />
                                        </SkeletonTheme>
                                    ) : (
                                        <img
                                            src="assets/images/img/jeffrey.webp"
                                            alt="nodata"
                                            style={{ width: '100%', display: 'flex' }}
                                        />
                                    )}
                                </div>
                                <div className="event-details text-justify" title='Parish Priest Message'>
                                    {isLoading ? (
                                        <Skeleton count={5} />
                                    ) : (
                                        <p dangerouslySetInnerHTML={{ __html: latestMessage?.content || 'No Data Available.' }} />
                                    )}
                                    <br />
                                    <div style={{ textAlign: 'right' }}>
                                        <p>Yours in Christ Jesus</p>
                                        <h4>Rev. Fr. Jeffrey SDB</h4>
                                        <p><b>Rector and Parish Priest</b></p>
                                    </div>
                                    {/* <Link to={'/parishpriestmessage'} className="read-more-btn"><center>Read More</center></Link> */}
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="campus-title">
                                <h2>Mass Timings</h2>
                            </div>
                            <Link to={'/masscalendar'}>
                                <div className="timeline">
                                    <div className="event">
                                        <div className="event-icon event-icon-weekdays">
                                            <i className="fa fa-calendar" />
                                        </div>
                                        <div className="event-details" title='Weekdays'>
                                            <h3>Masses in English - WEEKDAYS</h3>
                                            <p>6.00 a.m. - Lower Church</p>
                                            <p>11.00 a.m. - Lower Church (Tue, Thu &amp; Sat)</p>
                                            <p>6.30 p.m. - Lower Church</p>
                                        </div>
                                    </div>
                                    <div className="event">
                                        <div className="event-icon event-icon-sundays">
                                            <i className="flaticon-college" />
                                        </div>
                                        <div className="event-details" title='Sundays'>
                                            <h3>SUNDAYS</h3>
                                            <p>6.00 a.m. - Lower Church</p>
                                            <p>9.00 a.m. - Lower Church</p>
                                            <p>9.00 a.m - Catechism Mass</p>
                                            <p>11.00 a.m - Lower Church (Eng &amp; Tamil)</p>
                                            <p>6.00 p.m. - Upper Church</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutUs;
