import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
const Header = ({ menudata }) => {
    const location = useLocation();
    const url = location.pathname;
    return (
        <>
            <div className="top-header-area">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <div className="header-left-content">
                                <span id="emailLink"><i className="fa fa-envelope text-white"></i><a href="mailto:lourdesperambur@gmail.com" id="emailAnchor" className="text-white">&nbsp;lourdesperambur@gmail.com</a></span>
                                &nbsp; | &nbsp;  <span id="phoneLink"><i className="fa fa-phone text-white"></i><a href="tel:+91 44-26702100" id="phoneAnchor" className="text-white">&nbsp;+91 44-26702100</a></span>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="header-right-content">
                                <div className="list">
                                    <ul>
                                        <li className="list-inline-item mr-3 mb-0">
                                            <a
                                                href='/'
                                                id="youtubeIcon"
                                                className="youtube-btn"
                                            >
                                                <i className="fa fa-youtube" />
                                            </a>
                                        </li>
                                        <li className="list-inline-item mr-3 mb-0">
                                            <a
                                                href='/'
                                                id="facebookIcon"
                                                className="facebook-btn"
                                            >
                                                <i className="fa fa-facebook" />
                                            </a>
                                        </li>
                                        <li className="list-inline-item mr-3 mb-0">
                                            <a
                                                href='/'
                                                id="instagramIcon"
                                                className="instagram-btn"
                                            >
                                                <i className="fa fa-instagram" />
                                            </a>
                                        </li>
                                        <li className="list-inline-item mr-3 mb-0">
                                            <a
                                                href='/'
                                                id="twitterIcon"
                                                className="twitter-btn"
                                            >
                                                <i className="fa fa-twitter" />
                                            </a>
                                        </li>
                                        <li className="list-inline-item mr-3 mb-0">
                                            <a
                                                href='/'
                                                id="linkedinIcon"
                                                className="linkedin-btn"
                                            >
                                                <i className="fa fa-linkedin" />
                                            </a>
                                        </li>
                                        <li className="list-inline-item mr-3 mb-0">
                                            <a
                                                href='/'
                                                id="pinterestIcon"
                                                className="pinterest-btn"
                                            >
                                                <i className="fa fa-pinterest" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="navbar-area nav-bg-2">
                <div className="mobile-responsive-nav">
                    <div className="container">
                        <div className="mobile-responsive-menu">
                            <div className="logo">
                                <Link to="/">
                                    <img
                                        src="assets/images/logonew.png"
                                        className="main-logo"
                                        alt="logo"
                                    />
                                    <img
                                        src="assets/images/white-logo.png"
                                        className="white-logo"
                                        alt="logo"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="desktop-nav">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-md navbar-light">
                            <Link to="/" className='newmaillogo'>
                                <img src="/assets/images/logonew.png" className="main-logo" alt="logo" />
                                <span className="brand-text">Lourdes Shrine Perambur</span>
                            </Link>
                            <div
                                className="collapse navbar-collapse mean-menu"
                                id="navbarSupportedContent"
                            >
                                <ul className="navbar-nav ms-auto">
                                    {menudata?.map((menuItem, index) => (
                                        <li key={index} className="nav-item" >
                                            {menuItem.children ? (
                                                <Link to={menuItem.url} className={`nav-link dropdown-toggle  ${menuItem.children.some(child => url.includes(child.url)) ? "activemain" : ""}`}>
                                                    {menuItem.label}
                                                </Link>
                                            ) : (
                                                <Link to={menuItem.url} className={`nav-link ${url=== menuItem.url ? "activemain" : ""}`}>
                                                    {menuItem.label}
                                                </Link>
                                            )}
                                            {menuItem.children && (
                                                <ul className="dropdown-menu">
                                                    {menuItem.children?.map((subItem, subIndex) => (
                                                        <li key={subIndex} className={`nav-item ${url===subItem.url ? "active" : ""}`}>
                                                            <Link to={subItem.url} className="nav-link">
                                                                {subItem.label}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
                <div className="others-option-for-responsive">
                    <div className="container">
                        <div className="dot-menu">
                            <div className="inner">
                                <div className="icon">
                                    <i
                                        className="ri-menu-3-fill"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasWithBothOptions"
                                        aria-controls="offcanvasWithBothOptions"
                                    />
                                    <div
                                        className="offcanvas offcanvas-start"
                                        data-bs-scroll="true"
                                        tabIndex={-1}
                                        id="offcanvasWithBothOptions"
                                        aria-labelledby="offcanvasWithBothOptionsLabel"
                                    >
                                        <div className="offcanvas-header">
                                            <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">
                                                Lourdes Shrine Perambur
                                            </h5>
                                            <button
                                                type="button"
                                                className="btn-close text-reset"
                                                data-bs-dismiss="offcanvas"
                                                aria-label="Close"
                                            />
                                        </div>
                                        <div className="offcanvas-body">
                                            <ul className="navbar-nav ms-auto">
                                                {menudata?.map((menuItem, index) => (
                                                    <li key={index} className={`nav-item ${menuItem.hasSubmenu ? 'has-submenu' : ''}`}>
                                                        {menuItem.children ? (
                                                            <a href={menuItem.url} className="nav-link dropdown-toggle">
                                                                {menuItem.label}
                                                            </a>
                                                        ) : (
                                                            <a href={menuItem.url} className="nav-link">
                                                                {menuItem.label}
                                                            </a>
                                                        )}
                                                        {menuItem.children && (
                                                            <ul className="dropdown-menu">
                                                                {menuItem.children.map((subItem, subIndex) => (
                                                                    <li key={subIndex} className="nav-item">
                                                                        <a href={subItem.url} className="nav-link">
                                                                            {subItem.label}
                                                                        </a>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </li>
                                                ))}

                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Header
