import React from "react";
import Slider from "./Components/Slider";
import HomeBar from "./Components/HomeBar";
import AboutUs from "./Components/AboutUs";
import News from "./Components/News";
import Video from "./Components/Video";
import HomeBox from "./Components/HomeBox";
import Counter from "./Components/Counter";
import Header from "./layout/partials/Header";
import Footer from "./layout/partials/Footer";
import axios from "axios";
import { useState, useEffect } from "react";
import { ApiUrl } from "./API/ApiUrl";
import LatestEvent from "./Components/LatestEvent";

const Home = () => {
  const [homedata, setHomedata] = useState(null);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/get/homepagee/sections`);
        localStorage.setItem("HomeData", JSON.stringify(response?.data?.data));
        setHomedata(response?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } 
    };
    const fetchpageData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/get/Pages`);
        const newData = response?.data?.pages;
        const existingData = JSON.parse(localStorage.getItem("Pages")) || [];
      
        const updatedData = Array.isArray(existingData) ? [...existingData, ...newData] : newData;
        
        localStorage.setItem("Pages", JSON.stringify(updatedData));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    
    fetchpageData();    
    fetchData();
  }, []);

  return (
    <>
      <Header menudata={homedata?.headermenudata} />
      <Slider sliderdata={homedata?.SlidesData} />
      <HomeBar />
      <AboutUs />
      <Counter />
      <News allnewsdata={homedata?.newsdata} />
      <HomeBox />
      <LatestEvent allnewsdata={homedata?.newsdata} />
      <Video />
      <Footer />
    </>
  );
};

export default Home;
