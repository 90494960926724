import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import MainLayout from "./layout";
import ContactUs from "./Pages/Contact/ContactUs";
import History from "./Pages/OurParish/History";
import PiousAssociation from "./Pages/Ministries/PiousAssociation";
import PrayerRequest from "./Pages/PrayerRequest/PrayerRequest";
import MassCalendar from "./Pages/Calendar/MassCalendar";
import MeetOurPriest from "./Pages/OurParish/MeetOurParish";
import Magazine from "./Pages/OurParish/MassTime";
import AllNews from "./Pages/LatestNews/AllNews";
import AllEvents from "./Pages/LatestNews/AllEvents";
import VideoGallery from "./Pages/Gallery/VideoGallery";
import PhotoGallery from "./Pages/Gallery/PhotoGallery";
import ParishPriestMessage from "./Pages/HomeSub/ParishPriestMessage";
import NewsLetter from "./Pages/Calendar/NewsLetter";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/*" element={<MainLayout />}>
          <Route path="contactus" element={<ContactUs />} />
          <Route path="history" element={<History />} />
          <Route path="parishgroups" element={<History />} />
          <Route path="masstimings" element={<History />} />
          <Route path="dbschool" element={<History />} />
          <Route path="savioschool" element={<History />} />
          <Route path="semiboarding" element={<History />} />
          <Route path="oldagehome" element={<History />} />
          <Route path="marriagebureau" element={<History />} />
          <Route path="jubilee" element={<History />} />
          <Route path="shrinestall" element={<History />} />
          <Route path="prayerrequest" element={<PrayerRequest />} />
          <Route path="thanksgiving" element={<PrayerRequest />} />
          <Route path="piousassociation" element={<PiousAssociation />} />
          <Route path="meetourpriest" element={<MeetOurPriest />} />
          <Route path="masscalendar" element={<MassCalendar />} />
          <Route path="magazines" element={<Magazine />} />
          <Route path="ournews" element={<AllNews />} />
          <Route path="ourevents" element={<AllEvents />} />
          <Route path="videogallery" element={<VideoGallery />} />
          <Route path="photogallery" element={<PhotoGallery />} />
          <Route path="parishpriestmessage" element={<ParishPriestMessage />} />
          <Route path="newsletter" element={<NewsLetter />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
