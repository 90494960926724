import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const LatestEvent = ({ allnewsdata }) => {
    const [loading, setLoading] = useState(true);
    const [data, setLatestEvents] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const noimage = "assets/images/img/noimage.webp";

    useEffect(() => {
        const newsevents = localStorage.getItem("HomeData");
        if (newsevents) {
            setLoading(false);
          setLatestEvents(JSON.parse(newsevents));
        }else{
          setLatestEvents(allnewsdata);
        }
    }, [allnewsdata]); 
    const latestEvents = data?.newsdata
    ? data?.newsdata
        .filter((news) => news.category_id === 14)
        .sort((a, b) => new Date(b.eventdate) - new Date(a.eventdate))
        .slice(0, 3)
    : [];
    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split('-');
        const formattedDate = new Date(`${year}-${month}-${day}`);
        const dayOfMonth = formattedDate.getDate().toString().padStart(2, '0');
        const monthName = formattedDate.toLocaleDateString('en-US', { month: 'long' });

        return { day: dayOfMonth, month: monthName };
    };
    return (
        <>
            <div className="events-area pt-50 pb-40">
                <div className="container">
                    <div className="section-title text-center">
                        <p className="subtitle">Read Events</p>
                        <h4 className="title">Latest Events</h4>
                    </div>
                    {latestEvents.length > 0 ? (
                        <div className="row justify-content-center">
                            {loading ? (
                                <div className='col-lg-12 text-center text-black'><b><center>Loading Data...</center></b></div>
                            ) : latestEvents.map((event, index) => (
                                <div className="col-lg-4 col-md-6" key={index}>
                                    <div className="single-events-card style-4">
                                        <div className="events-image">
                                            <Link to={`ourevents?eventid=${event?.id}`}>
                                                <img src={event?.media_url || noimage} alt="No Event" />
                                            </Link>
                                            <div className="date">
                                                <span>{formatDate(event?.eventdate).day}</span>
                                                <p>{formatDate(event?.eventdate).month}</p>
                                            </div>
                                        </div>
                                        <div className="events-content">
                                            <div className="admin">
                                                <p>
                                                    <i className="fa fa-users" />&nbsp;
                                                    By Admin
                                                </p>
                                            </div>
                                            <Link to={`ourevents?eventid=${event?.id}`} className='latestnews'>
                                                <h3>{event?.title}</h3>
                                            </Link>
                                            <p>{event?.eventdate}</p>
                                            <Link to={`ourevents?eventid=${event?.id}`} className="read-more-btn"> Read More <i className="flaticon-next" /></Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className='col-lg-12 text-center text-black mt-5'><b><center>No Latest Events Available</center></b></div>
                    )}
                </div>
            </div>
        </>
    );
};

export default LatestEvent;
