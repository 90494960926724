import React from "react";
import { Outlet } from "react-router-dom";
import axios from "axios";
import { ApiUrl } from "../API/ApiUrl";
import { useEffect, useState } from "react";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
const MainLayout = () => {
  const [Homedata, setHomedata] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/get/homepagee/sections`);
        localStorage.setItem("HomeData", JSON.stringify(response?.data?.data));
        setHomedata(response?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <Header menudata={Homedata?.headermenudata} />
      <div style={{ minHeight: "66.5vh", padding: "10px" }}>
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default MainLayout;
