import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import styled from 'styled-components';
import { Modal, Button } from 'react-bootstrap';

const StyledCalendarContainer = styled.div`
  max-width: 1500px;
  margin: auto;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f5f5f5;
`;

const StyledCalendarHeader = styled.h2`
  color: #333;
  text-align: center;
  margin-right: 3rem;
`;

const StyledCalendar = styled(FullCalendar)`
  font-size: 14px;

  .rbc-month-view {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  .rbc-header {
    background-color: #4285f4;
    color: #ffffff;
    font-weight: bold;
    padding: 10px;
    border-bottom: 2px solid #ffffff;
  }

  .rbc-day-bg {
    background-color: #f8f9fa;
  }

  .rbc-today {
    background-color: #4285f4;
    color: #ffffff;
  }

  .rbc-agenda-view {
    border-top: 2px solid #4285f4;
  }

  .rbc-agenda-date-cell,
  .rbc-agenda-time-cell {
    font-size: 16px;
    color: #333;
  }

  .rbc-agenda-event-cell {
    padding: 15px;
    margin: 10px 0;
    border-radius: 8px;
    background-color: #4285f4;
    color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;

    a {
      color: #ffffff;
      text-decoration: none;
    }

    &:hover {
      background-color: #3367d6;
    }
  }
`;

const MassCalendar = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [processedSchedule, setProcessedSchedule] = useState([]);
  const handleEventClick = (info) => {
      const title = info.event.title;
      const eventData = processedSchedule.find((event) => event.title === title);
      if (eventData) {
          setSelectedEvent(eventData);
          setShowModal(true);
      } else {
          console.error(`Event data not found for ${title}`);
      }
  };
  const handleBookMass = () => {
      console.log('Booking Mass:', selectedEvent.title);
      setShowModal(false);
  };
  const handleCancel = () => {
      setShowModal(false);
  };
  const processSchedule = () => {
      const massSchedule = [
          {
              title: 'English Mass (6:00 a.m.)',
              daysOfWeek: [1, 2, 3, 4, 5], // Monday to Friday
              startTime: '06:00:00',
              location: 'Lower Church',
              additionalInfo: 'Masses in English',
              allDay: true,
          },
          {
              title: 'English Mass (11:00 a.m.)',
              daysOfWeek: [2, 4, 6], // Tuesday, Thursday & Saturday
              startTime: '11:00:00',
              location: 'Lower Church',
              additionalInfo: 'Masses in English',
              allDay: true,
          },
          {
              title: 'English Mass (6:30 p.m.)',
              daysOfWeek: [1, 2, 3, 4, 5], // Monday to Friday
              startTime: '18:30:00',
              location: 'Lower Church',
              additionalInfo: 'Masses in English',
              allDay: true,
          },
          {
              title: 'English Mass (9:00 a.m.)',
              daysOfWeek: [0], // Sunday
              startTime: '09:00:00',
              location: 'Lower Church',
              additionalInfo: 'Masses in English',
              allDay: true,
          },
          {
              title: 'Catechism Mass (9:00 a.m.)',
              daysOfWeek: [0], // Sunday
              startTime: '09:00:00',
              location: 'Catechism Mass',
              additionalInfo: 'Catechism Mass',
              allDay: true,
          },
          {
              title: 'English & Tamil Mass (11:00 a.m.)',
              daysOfWeek: [0], // Sunday
              startTime: '11:00:00',
              location: 'Lower Church',
              additionalInfo: 'Masses in English (Eng & Tamil)',
              allDay: true,
          },
          {
              title: 'English Mass (6:00 p.m.)',
              daysOfWeek: [0], // Sunday
              startTime: '18:00:00',
              location: 'Upper Church',
              additionalInfo: 'Masses in English',
              allDay: true,
          },
      ];

      const tamilMassSchedule = [
          {
              title: 'காலை 6.40 (a.m.)',
              daysOfWeek: [1, 3, 5], // Monday, Wednesday, Friday
              startTime: '06:40:00',
              location: 'கீழ் கோவில்',
              additionalInfo: 'திங்கள், புதன், வெள்ளி',
              allDay: true,
          },
          {
              title: 'காலை 11.00 (a.m.)',
              daysOfWeek: [1, 3, 5], // Monday, Wednesday, Friday
              startTime: '11:00:00',
              location: '(திங்கள், புதன் மற்றும் வெள்ளி)',
              additionalInfo: '',
              allDay: true,
          },
          {
              title: 'மாலை 6.30 (p.m.)',
              daysOfWeek: [1, 3, 5], // Monday, Wednesday, Friday
              startTime: '18:30:00',
              location: 'கீழ் கோவில்',
              additionalInfo: '',
              allDay: true,
          },
          {
              title: 'காலை 6.00 (a.m.)',
              daysOfWeek: [0], // Sunday
              startTime: '06:00:00',
              location: 'மேல் கோவில்',
              additionalInfo: '',
              allDay: true,
          },
          {
              title: 'காலை 7.30 (a.m.)',
              daysOfWeek: [0], // Sunday
              startTime: '07:30:00',
              location: 'மேல் கோவில் மற்றும் கீழ் கோவில்',
              additionalInfo: '',
              allDay: true,
          },
          {
              title: 'காலை 8.00 (a.m.)',
              daysOfWeek: [0], // Sunday
              startTime: '08:00:00',
              location: 'சி.சி.பாக்கம்',
              additionalInfo: '',
              allDay: true,
          },
          {
              title: 'காலை 9.00 (a.m.)',
              daysOfWeek: [0], // Sunday
              startTime: '09:00:00',
              location: 'மறைக்கல்வி மாணவர்கள்',
              additionalInfo: '',
              allDay: true,
          },
          {
              title: 'காலை 11.00 (a.m.)',
              daysOfWeek: [0], // Sunday
              startTime: '11:00:00',
              location: '(பிரதி ஞாயிறு ஆங்கிலம் / தமிழ்) கீழ் கோவில்',
              additionalInfo: '',
              allDay: true,
          },
          {
              title: 'மாலை 6.00 (p.m.)',
              daysOfWeek: [0], // Sunday
              startTime: '18:00:00',
              location: 'கீழ் கோவில்',
              additionalInfo: '',
              allDay: true,
          },
      ];
      const combinedSchedule = [...massSchedule, ...tamilMassSchedule];
      setProcessedSchedule(combinedSchedule);
  };

  useEffect(() => {
      processSchedule();
  }, []);

    return (
        <>
            <br />
            <StyledCalendarContainer>
                <StyledCalendarHeader>Mass Schedule</StyledCalendarHeader>
                <StyledCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    weekends={true}
                    events={processedSchedule}
                    headerToolbar={{
                        start: 'prev,next today',
                        center: 'title',
                        end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
                    }}
                    dayMaxEvents={true}
                    dayMaxEventRows={2}
                    eventClick={handleEventClick}
                    eventContent={(info) => <div className="event-tag">{info.event.title}</div>}
                    editable={true}
                    timeFormat={{
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                    }}
                />

            </StyledCalendarContainer>
            <br />
            <Modal show={showModal} onHide={handleCancel} centered backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title>{selectedEvent ? selectedEvent.title : 'Book Mass'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-end">
                        <Button
                            variant="primary"
                            onClick={handleBookMass}
                            style={{ backgroundColor: '#002147', color: 'white', border: 'none' }}
                        >
                            Book Mass
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                            variant="secondary"
                            onClick={handleCancel}
                            style={{ backgroundColor: '#dc3545', color: 'white', border: 'none' }}
                        >
                            Close
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default MassCalendar;
