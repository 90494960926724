import React from 'react'
import { Link } from 'react-router-dom'
const HomeBar = () => {
    return (
        <>
            <div className="eduction-area pb-40">
                <div className="container-fluid p-0">
                    <div className="row g-0">
                        <div
                            className="col-lg-3 col-md-6"
                        >
                            <div className="single-education-card bg-3">
                                <div className="edication-content">
                                    <div className="icon">
                                        <i className="flaticon-college" />
                                    </div>
                                    <Link to="/history" className="read-more-btn white-color">
                                        <h3>History of Lourdes Shrine</h3>
                                    </Link>
                                    <Link to="/history" className="read-more-btn white-color">
                                        History
                                        <i className="flaticon-next" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 col-md-6"
                        >
                            <div className="single-education-card bg-1">
                                <div className="edication-content">
                                    <div className="icon">
                                        <i className="flaticon-clock" />
                                    </div>
                                    <Link to="/masstimings" className="read-more-btn white-color">
                                        <h3>Mass Timings</h3>
                                    </Link>
                                    <Link to="/masstimings" className="read-more-btn white-color">
                                        Mass Timings
                                        <i className="flaticon-next" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 col-md-6"
                        >
                            <div className="single-education-card bg-2">
                                <div className="edication-content">
                                    <div className="icon">
                                        <i className="flaticon-user" />
                                    </div>
                                    <a href='https://preprod-lourdes.cawita.com/en/bernadette-soubirous/' target="_blank" rel="noreferrer" className="read-more-btn white-color">
                                        <h3>St. Bernadette Soubirous</h3>
                                    </a>
                                    <a href='https://preprod-lourdes.cawita.com/en/bernadette-soubirous/' target="_blank" rel="noreferrer" className="read-more-btn white-color">
                                        St. Bernadette Soubirous
                                        <i className="flaticon-next" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 col-md-6"
                        >
                            <div className="single-education-card bg-4">
                                <div className="edication-content">
                                    <div className="icon">
                                        <i className="flaticon-athletics" />
                                    </div>
                                  <a href='https://www.lourdes-france.org/' target="_blank" rel="noreferrer" className="read-more-btn white-color">
                                        <h3>Message of Lourdes</h3>
                                    </a>
                                    <a href='https://www.lourdes-france.org/' target="_blank" rel="noreferrer" className="read-more-btn white-color">
                                        Message of Lourdes
                                        <i className="flaticon-next" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HomeBar
