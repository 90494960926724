import React, { useState, useEffect } from "react";
import SimpleImageSlider from "react-simple-image-slider";
const SkeletonLoader = () => {
    return (
        <div className="skeleton-loader">
            <div className="skeleton-image" style={{ height: 500 }}></div>
        </div>
    );
};

const Slider = (sliderData) => {
    const [cachedImages, setCachedImages] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!sliderData || !sliderData.sliderdata) {
            return;
        }

        const images = sliderData.sliderdata?.map(item => ({ url: item.image }));
        const cachedImagesString = localStorage.getItem("sliderImages");
        const cachedImagesParsed = cachedImagesString ? JSON.parse(cachedImagesString) : [];
        if (JSON.stringify(cachedImagesParsed) !== JSON.stringify(images)) {
            localStorage.setItem("sliderImages", JSON.stringify(images));
            setCachedImages(images);
        } else {
            setCachedImages(cachedImagesParsed);
        }

        setLoading(false);
    }, [sliderData]);

    if (loading) {
        return <SkeletonLoader />;
    }

    if (cachedImages.length === 0) {
        return null;
    }

    return (
        <div>
            <SimpleImageSlider
                width={'100%'}
                height={500}
                images={cachedImages}
                showBullets={true}
                showNavs={true}
                loop={true}
                autoPlay={false}
                navStyle={2}
            />
        </div>
    );
}

export default Slider;
