import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
    const currentYear = new Date().getFullYear();
    useEffect(() => {
        const handleScrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        };

        const goTopButton = document.querySelector('.go-top');

        if (goTopButton) {
            goTopButton.addEventListener('click', handleScrollToTop);
        }

        return () => {
            if (goTopButton) {
                goTopButton.removeEventListener('click', handleScrollToTop);
            }
        };
    }, []);
    return (
        <>
            <div className="footer-area pt-70 pb-30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-sm-6">
                            <div className="footer-widjet">
                                <div className='section-headings'>
                                    <h3 className="text-white entry-title">History</h3>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <img
                                            src="assets/images/logonew.png"
                                            title='Lourdes Shrine Perambur'
                                            className="main-logos"
                                            alt=""
                                            style={{
                                                display: "block",
                                                width: "100px",
                                                height: "90px",
                                                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                                borderRadius: "50%",
                                            }}
                                        />
                                    </div>
                                    <div className="col-9">
                                        <p style={{ color: "white", textAlign: "justify", fontSize: 14 }}>
                                            In 1879, in commemoration of the silver jubilee of the proclamation of the dogma of the Immaculate Conception, Fr. H. Hennessey, Parish Priest of St. Andrew’s Church, Vepary, Madras, built a chapel at Perambur.
                                        </p>
                                    </div>
                                    <p style={{ color: "white", textAlign: "justify", fontSize: 14 }}>
                                        This chapel was blessed and dedicated to Our Lady of Lourdes in 1880 and served the spiritual needs of the catholics then residing in the locality.
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-6">
                            <div className="footer-widjet">
                                <div className='section-headings'>
                                    <h3 className="text-white entry-title">Quick Links</h3>
                                </div>
                                <div className="list">
                                    <ul className="quick-links">
                                        <li>
                                            <Link to={'/history'}><i className="fa fa-angle-right"></i> History</Link>
                                        </li>
                                        <li>
                                            <Link to={'/newsletter'}><i className="fa fa-angle-right"></i> News Letter</Link>
                                        </li>
                                        <li>
                                            <Link to={'/videogallery'}><i className="fa fa-angle-right"></i> Video Gallery</Link>
                                        </li>
                                        <li>
                                            <Link to={'/magazines'}><i className="fa fa-angle-right"></i> E-Magazines</Link>
                                        </li>
                                        <li>
                                            <Link to={'/photogallery'}><i className="fa fa-angle-right"></i> Photo Gallery</Link>
                                        </li>
                                        <li>
                                            <Link to={'/contactus'}><i className="fa fa-angle-right"></i> Contact Us</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="footer-widjet">
                                <div className='section-headings'>
                                    <h3 className="text-white entry-title">Contact Us</h3>
                                </div>
                                <span id='emailLink'>
                                    <p className="text-white">
                                        <span className="theme-clr"><i className='fa fa-map-marker'></i></span>&nbsp;&nbsp;Our Lady of Lourdes Shrine <br /> Paper Mills Road , Perambur, <br /> Chennai - 600 011 <br /> Tamil Nadu , South India
                                    </p>
                                    <p className="text-white">
                                        <span className="theme-clr"><i className='fa fa-phone'></i></span>&nbsp;&nbsp;<a href="tel:+91 44-2670 1100" id="phoneAnchor" className="text-white">
                                            &nbsp;+91 44-2670 1100
                                        </a>
                                    </p>
                                    <p className="text-white">
                                        <span className="theme-clr"><i className='fa fa-envelope'></i></span>&nbsp;&nbsp;<a
                                            href="mailto:peramburlourdesshrine@gmail.com"
                                            id="emailAnchor"
                                            className="text-white"
                                        >
                                            &nbsp;peramburlourdesshrine@gmail.com
                                        </a>
                                    </p>
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="footer-widjet">
                                <div className='section-headings'>
                                    <h3 className="text-white entry-title">Our Location</h3>
                                </div>
                                <div className="list">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.843732084267!2d80.23618708440311!3d13.109084278911553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5265b73211d32b%3A0xda5ec2bc77c1c7e2!2sOur%20Lady%20of%20Lourdes%20Shrine!5e0!3m2!1sen!2sin!4v1712839252208!5m2!1sen!2sin"
                                        width="100%"
                                        height={200}
                                        style={{ border: 0 }}
                                        allowFullScreen=""
                                        loading="lazy"
                                        title='loreto'
                                        referrerPolicy="no-referrer-when-downgrade"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-8 col-sm-6 text-center">
                            <div className="copy">
                                <p className='text-white' id="footcopynew">
                                    &copy; {currentYear} <a
                                        href="https://boscosofttech.com/"
                                        target="_blank"
                                        id="footcopynew"
                                        className='text-white'
                                        rel="noreferrer"
                                        style={{ textDecoration: 'none', color: '#fff' }}
                                    >
                                        BoscoSoft Technologies
                                    </a>
                                    &nbsp;&nbsp;All rights reserved.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="go-top">
                    <i className="ri-arrow-up-s-line" />
                    <i className="ri-arrow-up-s-line" />
            </div>
        </>
    )
}

export default Footer
