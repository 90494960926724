import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ApiUrl } from '../../API/ApiUrl';
const PiousAssociation = () => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/get/gallery_images`);
        setData(response?.data?.data);

      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filterdata = data.filter((item) => item.categoryid === 4)

  if (loading) {
    return (
      <div className="text-center mt-5">
        loading...
      </div>
    );
  }
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  return (
    <>
      <div className="container subpage">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="heading text-center mb-4">Pious Association</h2>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={6000}
              keyBoardControl={true}
              customTransition="transform 300ms ease-in-out"
              transitionDuration={300}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              {filterdata?.map((cause, index) => (
                <div key={index} className="causes-card" style={{ margin: '0 10px' }}>
                  <div className="causes-content">
                    <div className="causes-thumb">
                      <img src={cause.image} alt="causes" />
                    </div>
                    <div className="causes-details">
                      {/* <h3>{cause.title}</h3>
                                    <p>{cause.description}</p> */}
                      {/* <Link to={`ourcause?causeid=${cause?.id}`}>Read More</Link> */}
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </>
  )
}

export default PiousAssociation
